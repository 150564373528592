import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import AppBillboard from "../components/appBillboard";

import "./reset.css";
import "./main.css";

export default function Home() {
  return (
    <Layout>
      <SEO title="Own a piece of Europe's most famous billboard!" />
      <AppBillboard />
    </Layout>
  );
}
